import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'

import '../main.scss'

class Contact extends Component {
    render() {
        return (
            <Grid container direction="column" justify="center" alignItems="center" id="contact">
                <svg className="center" width="80" height="20">
                    <line className="svgLine" x1="0" y1="0" x2="80" y2="0" />
                    <polygon className="svgTriangle" points="0,5 80,5 40,15" />
                </svg>
                <span className="title">Contact</span>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Grid item xs={7} className="textAndImg">
                        <div className="logotext">
                            <p className="center text">
                                Par mail (de préférence) : lelandreau.equitation@yahoo.fr <br /> Ou par
                                téléphone : 07 80 35 36 16
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={7} className="textAndImg">
                        <p className="center text" style={{ margin: '0' }}>
                            Le Landreau Équitation
                            <br />
                            Lieu dit du Landreau
                            <br />
                            44310 Saint Philbert de Grand-Lieu
                            <br />
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default Contact
